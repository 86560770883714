import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Artist Talks`}</h1>
    <h4>{`Issue #1: Live Coding ft. Idea Unsound`}</h4>
    <Video videoId="xRSuVyaLLPE" alt="Issue #1: Live Coding" mdxType="Video" />
    <h4>{`Issue #2: Berlin ft. James Fish`}</h4>
    <Video videoId="e6wzqqQ532M" alt="Issue #2: Berlin" mdxType="Video" />
    <h4>{`Issue #3: Turning Pro ft. Atish`}</h4>
    <Video videoId="p8_19P9InlU" alt="Issue #3: Turning Pro" mdxType="Video" />
    <h2>{`Format`}</h2>
    <p>{`The Studio Collective hosts and films artist talks to give an inside look into the world of music. We've hosted `}<a parentName="p" {...{
        "href": "https://www.instagram.com/atishmusic/"
      }}>{`Atish`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/papa____molly/"
      }}>{`Molly Monahan`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/jamesfishmusic/"
      }}>{`James Fish`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/ideaunsound/"
      }}>{`Idea Unsound`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.instagram.com/nicholaspaul26/"
      }}>{`Nicholas Paul`}</a>{`.`}</p>
    <p>{`The meetups take place on a weeknight and typically follow this structure:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`7pm`}</strong>{` | Doors`}</li>
      <li parentName="ul"><strong parentName="li">{`8pm`}</strong>{` | Interview and Q&A with guest musician`}</li>
      <li parentName="ul"><strong parentName="li">{`9pm`}</strong>{` | Music listening & hangout`}</li>
    </ul>
    <p>{`We aim to create a casual environment for music nerds to hang out, learn from each other, and meet people to collaborate with. We'd like to build a supportive community of musicians who plan to invest years in the pursuit of excellence in their instrument, in sound design, in composition, in production, or in performance. `}</p>
    <p>{`San Francisco is a city with a rich musical history and a vibrant concert and club scene, but few active musicians live here. Our city is filled with exceptional people - intelligent, kind, hard working, and fast learners. We're curious to see what happens if we can inspire this passion and energy into music production and performance.`}</p>
    <h2>{`Stay updated`}</h2>
    <p>{`We'll be sharing information via instagram. Follow `}<a parentName="p" {...{
        "href": "https://instagram.com/studiocollectivesf"
      }}><strong parentName="a">{`@studiocollectivesf`}</strong></a>{` for updates.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      